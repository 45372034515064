import React, { useState } from 'react';
import CardItem from './CardItem'
import './Cards.css'
import charlie from '../images/charlie.png'
import brad from '../images/brad.png'
import beverly from '../images/beverly.png'
import will from '../images/will.png'
import max from '../images/max.png'
import Popup from './PopUp'



function Cards() {
  const [isOpenWill, setIsOpenWill] = useState(false);
  const [isOpenCharlie, setIsOpenCharlie] = useState(false);
  const [isOpenMax, setIsOpenMax] = useState(false);
  const [isOpenBrad, setIsOpenBrad] = useState(false);
  const [isOpenBev, setIsOpenBev] = useState(false);

  const willBio =  '\nWilliam Beazley was born and raised in the property tax business and has over ten (10) years of specialized experience in healthcare (hospitals, senior living, medical office, surgery centers, free-standing emergency departments) and hospitality assets. He holds the MAI designation by the Appraisal Institute and brings a deep knowledge of valuation methodology related to complex assets. \n\nPrior to Slate, Mr. Beazley was a Senior Director, Offer Management at ARGUS Software and previously, Senior Director of Property Tax at Altus Group US. Additionally, Will was an associate project manager at ADAMS Management Services whose mission is to advocate the interests of healthcare institutions in the planning and management of their capital programs. \n\nEDUCATION\n Undergraduate - Texas A&M University (Finance and Business Honors)'
  const charlieBio = '\nCharlie Young has been in the property tax industry since 2017 and holds the CMI designation from the Institute of Professionals in Taxation.  Over his career, Mr. Young has focused on complex commercial property types including healthcare (hospitals, senior living, medical office, surgery centers, FSED’s) and hospitality assets.  Charlie has reduced the property tax burden for clients across the country with his extensive knowledge of the intangible value embedded within complex assets. \n\nHe is a co-founder and principal of Slate Property Tax Solutions, a property tax consulting firm that helps commercial property owners effectively manage their property tax liability.  Before starting Slate, Charlie Young was a Senior Manager, Property Tax at Altus Group US. \n\nCharlie was born and raised in Southlake, Texas and attended Dallas Jesuit for high school.  He spends most of his free time with his Fiancée, Kelsey, his German Shepherd, Goose, or on the water with a fly rod. \n\nEDUCATION \nTexas A&M University - BBA Finance'
  const maxBio = '\nB. Max Row, MAI has over 30 years of experience in property valuation, appeals, and litigation support across a broad spectrum of property types. Since 1997 his practice has specialized in the evaluation of healthcare facilities.  He has testified as an expert witness in depositions, courts, and property tax proceedings throughout the United States.  His clients have included governmental agencies, attorneys, corporations, banks, REITs and individuals.  Max holds Certified General Appraiser licenses in several states and the MAI designation from the Appraisal Institute. \n\nMax’s career in the real estate valuation and consulting field has specialized in the valuation of healthcare facilities including hospitals (general acute care, long-term acute care, rehabilitation and psychiatric); hospital-related properties (surgery centers, imaging centers); medical office buildings; nursing homes; and senior housing (independent and assisted living).  Project experience within the healthcare industry has included appraisals of hospitals and related healthcare facilities for acquisitions, divestitures, financing and governmental agencies; medical office buildings and portfolios for various healthcare REITs; and ad valorem tax appraisals for healthcare facilities.  Max’s healthcare valuation practice has spanned across the United States. \n\nEDUCATION \nTexas A&M University - Bachelor of Science'
  const bevBio = '\nBeverly Kirkpatrick has 37 years of experiential knowledge in the property tax industry from administrative to management.  She has assisted consultants with all aspects of the property tax consulting field, including (but not limited too) maintaining tax software with assessments, appeal deadlines, appeal filings, hearing schedules, securing and auditing property tax statements and client reporting.  Beverly has managed a team that worked in all 50 states (and DC) and was ultimately responsible for appeal and tax statement deadlines, along with processing assessments and tax statements for more than 25,000 parcels per year'
  const bradBio = '\nBrad Matheidas began his journey in real estate valuation and consulting while still in school at the University of Texas – Arlington, working on national, commercial property for Morey & Associates and Crosson & Dannis, both Dallas appraisal firms before going to work briefly at Pier 1 Imports in Fort Worth.  Brad started his property tax career in 1996 with Rosemont & Associates Dallas office, moving to Complex Property Advisors Corp in Southlake, TX in 1998.  He remained for 25 years finishing as a Senior Director and Lead for its Healthcare Real Property Division under the ownership of Altus Group. \n\nToday, Brad is a co-founder of Slate Property Tax Solutions based in Southlake, TX.  Slate files and administers appeals while providing property tax guidance for both commercial and complex property on a national basis.  Slate offers a full spectrum of property tax services for general commercial property (retail, office, industrial and multi-family) and for specialty or complex properties (healthcare, hospitality, stadiums, together with special purpose / single user type facilities that may have outside market considerations that do not lend themselves to mass appraisal). \n\nEDUCATION \nUniversity of Texas – Arlington, BBA – Real Estate'
 
  const togglePopupWill = () => {
    setIsOpenWill(!isOpenWill);
  }

  const togglePopupCharlie = () => {
    setIsOpenCharlie(!isOpenCharlie);
  }

  const togglePopupMax = () => {
    setIsOpenMax(!isOpenMax);
  }

  const togglePopupBev = () => {
    setIsOpenBev(!isOpenBev);
  }

  const togglePopupBrad = () => {
    setIsOpenBrad(!isOpenBrad);
  }

  return (
    <div className='cards' id='cards'>
        <h1 className='card__header'>Meet Our Team</h1>
        <div className="cards__container">
            <div className="cards__wrapper">
                <ul className='cards__items'>
                    <CardItem 
                    src={will}
                    text='Will Beazley, MAI'
                    label='Principal'
                    phone='214-214-2222'
                    email='Will.Beazley@slatepts.com'
                    onClick={togglePopupWill}
                    >
                    </CardItem>
                    {isOpenWill && <Popup
                      src={will}
                      text={willBio}
                      label='Will Beazley, MAI - Principal'
                      handleClose={togglePopupWill}
                    />}
                    <CardItem 
                    src={charlie}
                    text='Charlie Young, CMI'
                    label='Principal'
                    phone='214-214-2222'
                    email='Charlie.Young@slatepts.com'
                    onClick={togglePopupCharlie}
                    />
                    {isOpenCharlie && <Popup
                      src={charlie}
                      label='Charlie Young, CMI - Principal'
                      text= {charlieBio}
                      handleClose={togglePopupCharlie}
                    />}                    
                    <CardItem 
                    src={brad}
                    text='Brad Matheidas'
                    label='Principal'
                    phone='214-214-2222'
                    email='Brad.Matheidas@slatepts.com'
                    onClick={togglePopupBrad}
                    />
                    {isOpenBrad && <Popup
                      src={brad}
                      text={bradBio}
                      label='Brad Matheidas - Principal'
                      handleClose={togglePopupBrad}
                    />}     
                    <CardItem 
                    src={max}
                    text='Max Row, MAI'
                    label='Director of Sales'
                    phone='214-214-2222'
                    email='Max.Row@slatepts.com'
                    onClick={togglePopupMax}
                    />
                    {isOpenMax && <Popup
                      src={max}
                      text={maxBio}
                      label='Max Row, MAI - Director of Sales'
                      handleClose={togglePopupMax}
                    />}                   
                    <CardItem 
                    src={beverly}
                    text='Beverly Kirkpatrick'
                    label='Director of Tax Admin'
                    phone='214-214-2222'
                    email='Beverly.Kirkpatrick@slatepts.com'
                    onClick={togglePopupBev}
                    />
                    {isOpenBev && <Popup
                      src={beverly}
                      text={bevBio}
                      label='Beverly Kirkpatrick - Director of Tax Admin'
                      handleClose={togglePopupBev}
                    />}
                </ul>
            </div>
        </div>
    </div>
    
  )
}

export default Cards