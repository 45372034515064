import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import video from '../videos/dallas.mp4'
import logoimg from '../images/slatenobackground.png'

function HeroSection() {
  return (
    <div className='hero-container' id='hero-container'>
      <video autoPlay={true} 
        loop={true}
        controls={false} 
        playsInline
        muted 
        src={video}
        type="video/mp4" />
      <div className='logo-container'>
        <img src={logoimg} className='logo-container-img'/>
      </div>
      <div className='hero-btns'>
        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--medium'
          //onClick={console.log('hey')}
        >
          Lower My Property Tax
        </Button> */}
      </div>
    </div>
  );
}

export default HeroSection;