import React from 'react'
import { Link } from 'react-router-dom'
import './CardItem.css'

function CardItem(props) {
    const mailtoLink = `mailto:${props.email}`;
    const callLink = `tel:${props.phone}`
    return (
    <>
        <li className='cards__item'>
            <div className='cards__item__link'>
                <figure className="cards__item__pic-wrap" data-category={props.label} 
                onClick={props.onClick}
                >
                    <img src={props.src} alt="Travel Image" className="cards__item__img" />
                    <button class="overlay">View Bio</button>

                </figure>
                <div className="cards__item__info">
                    <h5 className="cards__item__text">{props.text}</h5>
                    {/* <h6 className='cards__item__phone'>
                        <a href={callLink} className='cards__item__phone_text'>
                            {props.phone}                       
                        </a>
                    </h6> */}
                    <h6 className='cards__item__email'>
                        <a href={mailtoLink} className='cards__item__email_text'>
                            {props.email}
                        </a>
                    </h6>
                </div>
            </div>
        </li>
    </>
  )
}

export default CardItem