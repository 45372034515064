import React from 'react';
import './Button.css';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
  children,
  type,
  buttonStyle,
  buttonSize,
  onClick,
  scrollToSectionId,
  disabled // Pass the target section ID as a prop
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  // Combined onClick handler
  const handleButtonClick = () => {
    // If onClick prop is provided, call it
    if (typeof onClick === 'function') {
      onClick();
    }

    // Scroll to the specified section
    if (scrollToSectionId) {
      const element = document.getElementById(scrollToSectionId);
      if (element) {
        const yOffset = 1; // Adjust the offset as needed
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  return (
    <div className='btn-mobile'>
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={handleButtonClick}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
};
