import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-scroll';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          {/* <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={logoimg} alt="Logo" style={{ width: '150px', height: 'auto', alignItems: 'center', justifyContent: 'center'}}/>
          </Link> */}
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='hero-container' 
                className='nav-links' 
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                duration={500} // Adjust the duration of the scroll animation
                >
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='homeObjOne'
                className='nav-links'
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                duration={500} // Adjust the duration of the scroll animation
              >
                About Us
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='cards'
                className='nav-links'
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                duration={500} // Adjust the duration of the scroll animation
              >
                The Team
              </Link>
            </li>
            <li>
              <Link
                to='footer-container'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                duration={500} // Adjust the duration of the scroll animation
              >
                Contact Us
              </Link>
            </li>
          </ul>
          {button && 
          <Button 
            buttonStyle='btn--outline'
            scrollToSectionId='footer-container'
          >
            Contact Us
            </Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;