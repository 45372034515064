import React, {useState} from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import logoimg from '../images/slatenobackground.png';
import emailjs from '@emailjs/browser';
import {Phone, Mail} from 'react-feather';



function Footer() {
  const [nameValue, setNameValue] = useState();
  const [emailValue, setEmailValue] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [companyValue, setCompanyValue] = useState();
  const [titleValue, setTitleValue] = useState();
  const [messageValue, setMessageValue] = useState();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const handleNameChange = (event) => {
    setNameValue(event.target.value)
  };
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value)
  };
  const handlePhoneChange = (event) => {
    setPhoneValue(event.target.value)
  };
  const handleCompanyChange = (event) => {
    setCompanyValue(event.target.value)
  };
  const handleTitleChange = (event) => {
    setTitleValue(event.target.value)
  };
  const handleMessageChange = (event) => {
    setMessageValue(event.target.value)
  };

  function sendEmail(e) {
    e.preventDefault();
    const serviceID = 'service_n9409zq';
    const templateID = 'template_59i8k4h';
    const userID = '3XTtGwrnbB9EKekY6';
  
    const templateParams = {
      nameValue: nameValue,
      emailValue: emailValue,
      phoneValue: phoneValue,
      titleValue: titleValue,
      companyValue: companyValue,
      messageValue: messageValue
    };
  
    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully', response);
        setIsEmailSent(true)
      })
      .catch((error) => {
        console.error('Email not sent, error:', error);
      });
  }

  return (
    
    <div className='footer-container' id='footer-container'>
      <section className='footer-subscription'>
        <img src={logoimg} alt='Slate Property Tax Solutions Logo' className='logo-container-footer'/>
        <p className='footer-subscription-about'>We are glad you are considering Slate Property Tax Solutions to be your property tax partner.  For more information or to contact our team, please fill out the contact us form below and we will reach out to you directly.  Alternatively, you can reach out to our director of sales below.</p>
         <a className='large-icon-container'>
          <a className='icon-container' >
              <Phone className='icon' alt='Phone Icon'> </Phone>
              <a className="icon-text" href='tel:817-985-7784'>817-985-7784</a>

          </a>
          <a className="icon-container" >
            <Mail className='icon' alt='Email Icon'> </Mail>
            <a className="icon-text" href='mailto:Max.Row@slatepts.com'>Max.Row@slatepts.com</a>
          </a>
        </a>
        <p className='footer-subscription-heading'>
         Contact Us
        </p>
        {
          isEmailSent ? 
          (
            <div className="email-sent-message">
              <p>A member of our team will be in touch!</p>
            </div>
          ) 
          :
          (
          <div className='input-areas'>
            <form onSubmit={sendEmail}>
            <input
                className='footer-input'
                name='nameValue'
                type='name'
                placeholder='Name *'
                onChange={handleNameChange}
                value={nameValue}
              />
              <input
                className='footer-input'
                name='companyValue'
                type='text'
                placeholder='Company *'
                onChange={handleCompanyChange}
                value={companyValue}
              />
              <input
                className='footer-input'
                name='titleValue'
                type='title'
                placeholder='Job Title (optional)'
                onChange={handleTitleChange}
                value={titleValue}
              />
              <input
                className='footer-input'
                name='emailValue'
                type='email'
                placeholder='Email *'
                onChange={handleEmailChange}
                value={emailValue}
              />
              <input
                className='footer-input'
                name='phoneValue'
                type='phonenumber'
                placeholder='Phone Number *'
                onChange={handlePhoneChange}
                value={phoneValue}
              />
              <textarea
                className='footer-input'
                name='messageValue'
                type='message'
                placeholder='Message (optional)'
                onChange={handleMessageChange}
                value={messageValue}
                rows={4}
              />
              <Button 
                buttonStyle='btn--outline' 
                buttonSize='btn--large' 
                type='submit' 
                value='Send' 
                disabled={!nameValue && !emailValue && !phoneValue && !companyValue}>
                  Lower My Property Tax
              </Button>
            </form>
          </div>
          )
        }
        
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Help</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>LinkedIn</Link>
          </div>
        </div>
      </div>
      <small class='website-rights'>Slate Property Tax Solutions © 2023</small>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;