import React from "react";
import "./PopUp.css";

const Popup = (props) => {
  return (
    <div className="popup-box">
      <div className="box" style={{ whiteSpace: 'pre-line' }}>
        <span className="close-icon" onClick={props.handleClose}>
          x
        </span>
        <h1>{props.label}</h1>
        {/* {props.src && <img src={props.src} alt={props.label} className='img'/>} */}
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default Popup;