import React from 'react'
import '../../App.css'
import Cards from '../Cards';
import Footer from '../Footer';
import HeroSection from '../HeroSection'
import AboutUs from '../AboutUs';
import About from '../About'
import loan from '../../images/loan.png';
import receipt from '../../images/receipt.png'
import property from '../../images/property.png'
import first from '../../images/00.jpg'
import third from '../../images/02.jpg'
import second from '../../images/01.jpg'


  export const aboutone = {
    headline: 'Expertise',
    description:
      'You wouldn\'t hire a general practitioner to perform surgery.  Slate is built on advanced methodologies and industry knowledge to properly represent complex assets that don\’t lend themselves to general or mass valuation practices. \n\n',
    desc2: '\u2022 Two MAIs on staff',
    desc3: '\u2022 100+ years of combined property tax consulting / appraisal experience',
    desc4: '\u2022 Nationwide experience with the largest real estate owners and operators in America',
    color: true,
    sectionidnew: 'homeObjOne',

  }

  export const abouttwo = {
    headline: 'Service',
    description:
    'Property Tax representation is a relationship driven business. We partner with our clients to provide high quality service tailored to their specific needs.',
    desc2: '\u2022 Always know who is working on your property',
    desc3: '\u2022 Dedicated account manager and direct access to experts working your property',    
    desc4: '\u2022 National scope, personal service',
    color: false
  }

  export const aboutthree = {
    headline: 'Results',
    description:
      'We selected the word Solutions in our company name as we provide more than mere advice.  We deliver results to our clients by mitigating their property tax expense and administrative burden.',
    desc2: '\u2022 Identify and quantify obsolescence and intangibles to reduce property tax liability',
    desc3: '\u2022 Effectively articulate and present complex methodologies',
    desc4: '\u2022 Expert review of each property to identify all tax reduction opportunities',
    color: true
    }


function Home () {
    return (
        <>
            <HeroSection/>
            <About {...aboutone}/>
            <About {...abouttwo}/>
            <About {...aboutthree}/>

            {/* <AboutUs {...homeObjOne}/>
            <AboutUs {...homeObjTwo}/>
            <AboutUs {...homeObjThree}/> */}
            <Cards/>
            <Footer/>
        </>
    );
}

export default Home;