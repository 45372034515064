import React from 'react';
import './About.css';

function About({
    sectionid,
    description,
    desc2,
    desc3,
    desc4,
    headline,
    color,
    sectionidnew
}) {
  return (
    <>
      <div className={color ? 'background__section' 
            : 'background__section__white'} id={sectionidnew}>
        <div className='information__section'>
        <h1 className='heading__section'>
        {headline}
        </h1>
        <p className='description__section'>
          {description}
        </p>
        <p className='description__subsection'>
          {desc2}
        </p>
        <p className='description__subsection'>
          {desc3}
        </p>
        <p className='description__subsection'>
          {desc4}
        </p>
        </div>
      </div>
    </>
  );
}

export default About;